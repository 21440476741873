export type TScreen = {
    xs: '810px';

    sm: '1024px';

    md: '1240px';

    lg: '1366px';

    xl: '1600px';

    '2xl': '1920px';
};

export enum EScreenSize {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
}

export const screenSizes = {
    xs: '810px',
    sm: '1024px',
    md: '1240px',
    lg: '1366px',
    xl: '1600px',
    '2xl': '1920px',
};
