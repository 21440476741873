import {logger} from "@/shared/utils/logger";

export const buttonBackgroundCalculation = ({
    override_button_normal_background,
    override_button_normal_background_end,
    override_button_hover_background,
    override_button_hover_background_end,
    override_button_active_background,
    override_button_active_background_end,
    override_button_focus_background,
    override_button_focus_background_end,
}: {
    override_button_normal_background?: null | string,
    override_button_normal_background_end?: null | string,
    override_button_hover_background?: null | string,
    override_button_hover_background_end?: null | string,
    override_button_active_background?: null | string,
    override_button_active_background_end?: null | string,
    override_button_focus_background?: null | string,
    override_button_focus_background_end?: null | string,
}) => {
    const getFinal = (startColor: string, endColor: string) => startColor && endColor && startColor !== endColor ? `linear-gradient(90deg, ${startColor} 0%, ${endColor} 100%) !important` : startColor;
    const bcgNormal = getFinal(override_button_normal_background || '', override_button_normal_background_end || '');
    const bcgHover = getFinal(override_button_hover_background || '', override_button_hover_background_end || '');
    const bcgActive = getFinal(override_button_active_background || '', override_button_active_background_end || '');
    const bcgFocus = getFinal(override_button_focus_background || '', override_button_focus_background_end || '');
    return { bcgNormal, bcgHover, bcgActive, bcgFocus };
}
