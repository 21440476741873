'use client';
import { usePathname } from 'next/navigation';
import NextLink from 'next/link';
import { getLocaleFromPathname } from '@/shared/utils/url';
import { DEFAULT_LOCALE } from '@/shared/utils/constants';
import React, { ComponentProps, useEffect, useState } from 'react';
import { getBrowserCookie } from '@/shared/utils/utils';

type Props = Omit<ComponentProps<typeof NextLink>, 'href'> & {
    href: string;
};

const LocalizedLink = React.forwardRef<HTMLAnchorElement, Props>(
    ({ href, children, prefetch = false, ...props }, ref) => {
        const pathname = usePathname();
        const [locale, setLocale] = useState(getLocaleFromPathname(pathname) || DEFAULT_LOCALE);

        useEffect(() => {
            const cookieLocale = getBrowserCookie('locale');
            if (cookieLocale && cookieLocale !== locale) {
                setLocale(cookieLocale);
            }
        }, [locale]);

        // Check if href is a full path (starts with http:// or https://)
        const isFullPath = /^https?:\/\//.test(href);

        let hrefWithLocale = href;

        if (!isFullPath) {
            if (href.startsWith(`/${locale}`)) {
                console.warn(
                    `LocalizedLink: href "${href}" already includes locale. This may lead to unexpected behavior.`
                );
                hrefWithLocale = href;
            } else {
                hrefWithLocale = `/${locale}${href}`;
            }
        }

        return (
            <NextLink href={hrefWithLocale} ref={ref} prefetch={prefetch} {...props}>
                {children}
            </NextLink>
        );
    }
);

LocalizedLink.displayName = 'LocalizedLink';

export { LocalizedLink };
