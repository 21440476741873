'use client';

import { screenSizes } from '@/shared/utils/screensize';
import { is } from 'effector';
import { getImageProps } from 'next/image';
import { useState } from 'react';
type TInternalProps = {
    domain: string;
    mobileSrc: string;
    desktopSrc: string;
    desktopWidth: number;
    desktopHeight: number;
    mobileWidth: number;
    mobileHeight: number;
    alt?: string;
    sizes?: string;
};
export const StaticPicture = (props: TInternalProps) => {
    const [mobileSrc, setMobileSrc] = useState(props.mobileSrc as string);
    const [desktopSrc, setDesktopSrc] = useState(props.desktopSrc as string);

    const common = { alt: 'Fancy Image', sizes: '100vw' };
    const {
        props: { srcSet: desktop },
    } = getImageProps({
        alt: props.alt ?? common.alt,
        sizes: props.sizes ?? common.sizes,
        width: props.desktopWidth,
        height: props.desktopHeight,
        src: desktopSrc,
    });
    const {
        props: { srcSet: mobile, ...rest },
    } = getImageProps({
        alt: props.alt ?? common.alt,
        sizes: props.sizes ?? common.sizes,
        width: props.mobileWidth,
        height: props.mobileHeight,
        src: mobileSrc,
    });
    // trying to set src from the absolute url in case of the site is mirrored (like we have for zone-uat.msn.com)
    const handleError = () => {
        const absoluteUrlDesktop = `${props.domain}${props.desktopSrc}`;
        const absoluteUrlMobile = `${props.domain}${props.mobileSrc}`;
        setMobileSrc(absoluteUrlMobile);
        setDesktopSrc(absoluteUrlDesktop);
    };
    return (
        <picture>
            <source media={`(min-width: ${screenSizes.xs})`} srcSet={desktop} />
            <source srcSet={mobile} />
            {/* eslint-disable-next-line */}
            <img {...rest} style={{ width: '100%', height: 'auto', maxWidth: '610px' }} onError={handleError} />
        </picture>
    );
};
